import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", overflow: "auto", "min-height": "100vh" } },
    [
      _c("div", { staticClass: "container-login" }, [
        _c("div", { staticClass: "first-login-column" }, [
          _c("div", { staticClass: "login-form" }, [
            _c("div", { staticClass: "login-form-content" }, [
              _c("div", [
                _vm._m(0),
                !_vm.validating
                  ? _c("div", { staticClass: "login-form-header" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("welcome"))),
                      ]),
                      _c("span", { staticClass: "sub-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("welcome_dxa_login_content")) + " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "login-form-content" },
                  [
                    !_vm.validating
                      ? _c(
                          VForm,
                          {
                            ref: "form",
                            attrs: { "lazy-validation": _vm.lazy },
                            on: {
                              submit: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.checkForm()
                              },
                            },
                            model: {
                              value: _vm.valid,
                              callback: function ($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "div_email" },
                              [
                                _c("h3", { staticClass: "email_title" }, [
                                  _vm._v("E-mail"),
                                ]),
                                _c(VTextField, {
                                  staticClass: "my-input-style mt-2",
                                  attrs: {
                                    solo: "",
                                    flat: "",
                                    "background-color": "#D1D5DB",
                                    color: "#000",
                                    rules: [_vm.required],
                                    "data-test": "Login:Login:Email",
                                    placeholder: "name@example.com",
                                  },
                                  model: {
                                    value: _vm.user.Email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "Email", $$v)
                                    },
                                    expression: "user.Email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "div_password" },
                              [
                                _c("h3", { staticClass: "password_title" }, [
                                  _vm._v(_vm._s(_vm.$t("password"))),
                                ]),
                                _c(VTextField, {
                                  ref: "user.UserPassword",
                                  staticClass: "my-input-style mt-2",
                                  attrs: {
                                    solo: "",
                                    flat: "",
                                    "background-color": "#D1D5DB",
                                    color: "#000",
                                    rules: [_vm.required],
                                    "append-icon": _vm.show1
                                      ? "mdi-eye"
                                      : "mdi-eye-off",
                                    type: _vm.show1 ? "text" : "password",
                                    name: "password",
                                    "data-test": "Login:Login:Password",
                                    placeholder: "********",
                                  },
                                  on: {
                                    "click:append": function ($event) {
                                      _vm.show1 = !_vm.show1
                                    },
                                  },
                                  model: {
                                    value: _vm.user.UserPassword,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.user, "UserPassword", $$v)
                                    },
                                    expression: "user.UserPassword",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "forgot-password-container" },
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticClass: "recover_btn",
                                    attrs: {
                                      text: "",
                                      "data-test": "Login:Login:ForgotPassword",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "ForgotPasswordClient",
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("forgot_pwd")))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "pages-btn",
                              {
                                staticClass: "btn-login",
                                attrs: {
                                  type: "submit",
                                  block: "",
                                  dark: "",
                                  loading: _vm.loading,
                                  color: _vm.color,
                                  "data-test": "Login:Login:SingIn",
                                },
                              },
                              [_vm._v("Login")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "forgot-password-container-mobile",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "recover_btn_mobile",
                                    attrs: {
                                      "data-test": "Login:Login:ForgotPassword",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "ForgotPasswordClient",
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("forgot_pwd")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.validating
                      ? _c("ValidateRegister", {
                          attrs: { userEmail: _vm.userEmail },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "login-copyright" }, [
              _c("p", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("copyright_dealboard"))),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "second-login-column" }, [
          _c("div", { staticClass: "login-text-column" }, [
            _c("div", { staticClass: "login-text-column-container" }, [
              _vm._m(1),
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("dealboard_login_title"))),
              ]),
              _c("span", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("dealboard_login_subtitle"))),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dealboard-logo-mobile" }, [
      _c("img", {
        attrs: {
          src: require("../../../../assets/dealboard-logo-black.svg"),
          alt: "dealboard",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dealboard-logo" }, [
      _c("img", {
        attrs: {
          src: require("../../../../assets/dealboard-logo-white.svg"),
          alt: "dealboard",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }